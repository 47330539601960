import Home from './Components/Pages/Home/Home';
import Devices from './Components/Pages/Devices/Devices';
import Login from './Components/Pages/Login/Login';
import Footer from './Components/Common/Footer/Footer';
import LiveTracking from "./Components/Pages/LiveTracking/LiveTracking";
import Website from "./Components/Front/Website";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import Clients from "./Components/Pages/Clients/Clients";
import Partners from "./Components/Pages/Partners/Partners";
import i18next from "i18next";
import NotFound from "./Components/Pages/NotFound/NotFound";
import PromoterRoute from "./Components/Pages/PromoterRoute/PromoterRoute";
import WebHome from "./Components/Front/Home";
import About from "./Components/Front/About";
import Tariffs from "./Components/Front/Tariffs";
import Promoter from "./Components/Front/Promoter";
import Contacts from "./Components/Front/Contacts";
import AddPromoterRoute from "./Components/Pages/PromoterRoute/AddPromoterRoute";
import AdCampaigns from "./Components/Pages/AdCampaigns/AdCampaigns";
import PrivacyPolicy from "./Components/Front/PrivacyPolicy";
import Promotions from "./Components/Pages/Promotions/Promotions";
import Promoters from "./Components/Pages/Promoters/Promoters";
import {requestForToken} from './utils/firebase';
import Users from "./Components/Pages/Users/Users";
import Roles from "./Components/Pages/Roles/Roles";
import APKUpload from "./Components/Pages/APKUpload/APKUpload";
import Bookings from "./Components/Pages/Bookings/Bookings";
import {PostData} from "./Components/api_key/PostData";
import {Link} from "react-router-dom";
import Cities from "./Components/Pages/Cities/Cities";
import {Button, Spinner} from "react-bootstrap";

const NotAccessOfPage = (props) => {
    const [listSidebarMenuLink, setListSidebarMenuLink] = useState("")
    const [dataLoader, setDataLoader] = useState(true)
    useEffect(() => {
        if (localStorage.getItem('loginDetails') === null) {
            window.location = "/login"
            return;
        }
        setTimeout(() => setDataLoader(false), 3000);
        document.querySelector("#footer").style.display = "none"
        setListSidebarMenuLink(props.permissionList[0].supported_ui_paths)
    }, []);

    return (<>
        {dataLoader &&
            <div className="api-data-loader">
                <Spinner animation="grow" variant="info"/>
            </div>}
        <div className="container-fluid pt-5">
            <div className="form-input-content text-center error-page">
                <h4>{i18next.t("you_have_not_access")}</h4>
                <p className={"mt-3 d-flex flex-column align-items-center justify-content-center"}>
                    <Link className={"text-primary border-bottom border-primary mb-3"}
                          to={"/" + listSidebarMenuLink}>Home</Link>
                    <Button variant={"primary"} onClick={props.userLogout}>{i18next.t("logout")}</Button>
                </p>
            </div>
        </div>
    </>)
}

class App extends React.Component {
    constructor(props) {
        super(props);
        let sessionData = []
        if (localStorage.getItem('loginDetails') !== null) {
            sessionData = JSON.parse(localStorage.getItem('loginDetails'));
        } else {
            sessionData = [{uuid: "", username: ""}]
        }

        this.state = {
            hidePreloader: false,
            loginRedirect: false,
            uuid: sessionData.uuid,
            username: sessionData.full_name,
            sessionData: sessionData,
            listSidebarMenu: []
        }
        let main = document.querySelector("#main-wrapper")
        main.classList.add("show")
        this.userLogout = this.userLogout.bind(this)
        this.loadJavaScript = this.loadJavaScript.bind(this)
        requestForToken();
    }

    loadJavaScript() {
        // document.getElementById("content-body").style.paddingBottom = document.getElementById("footer").clientHeight + "px"
        document.getElementById("content-body").style.height = "calc(100vh - " + document.getElementById("footer").clientHeight + "px)"
        document.getElementById("content-body").style.overflow = "auto"
    }

    componentDidMount() {
        if (localStorage.getItem('loginDetails') !== null) {
            PostData("/frontCall/users/getUserPermissions")
                .then(result => {
                    this.setState({
                        listSidebarMenu: result.response.map(item => item.permission_key),
                        userPermissionList: result.response,
                    })
                })
        }
    }

    userLogout() {
        localStorage.removeItem('loginDetails');
        window.location = '/login'
        this.setState({loginRedirect: true})
    }

    render() {
        if (localStorage.getItem('loginDetails') !== null && !this.state.userPermissionList) {
            return;
        }
        return (
            <>
                <BrowserRouter>
                    <Routes>
                        <Route path="/login" element={
                            <Login pageTitle={i18next.t("title_login")} loginRedirect={this.state.loginRedirect}/>
                        }/>
                        <Route path="/" element={<Website pageTitle={i18next.t("title_home_doc")}/>}>
                            <Route path="" element={<WebHome pageTitle={i18next.t("title_home_doc")}/>}/>
                            <Route path="about" element={<About pageTitle={i18next.t("title_about_doc")}/>}/>
                            <Route path="tariffs" element={<Tariffs pageTitle={i18next.t("title_tariffs")}/>}/>
                            <Route path="promoter" element={<Promoter pageTitle={i18next.t("title_promoters_doc")}/>}/>
                            <Route path="contacts" element={<Contacts pageTitle={i18next.t("title_contacts")}/>}/>
                            <Route path="privacy_policy"
                                   element={<PrivacyPolicy pageTitle={i18next.t("title_privacy")}/>}/>
                        </Route>
                        <Route path="/dashboard" element={
                            (this.state.listSidebarMenu.includes('manage_dashboard'))
                                ? <Home
                                    permissionList={this.state.userPermissionList}
                                    pageTitle={"Backpack Media - " + i18next.t("dashboard")}
                                    headerTitle={i18next.t("dashboard")}
                                    userLogout={this.userLogout}
                                    loadFooterHeight={this.loadJavaScript}
                                    loginRedirect={this.state.loginRedirect}
                                />
                                : <NotAccessOfPage
                                    permissionList={this.state.userPermissionList}
                                    userLogout={(this.userLogout)}/>
                        }/>
                        <Route path="/devices" element={
                            (this.state.listSidebarMenu.includes('manage_devices'))
                                ? <Devices
                                    permissionList={this.state.userPermissionList}
                                    pageTitle={"Backpack Media - " + i18next.t("devices")}
                                    headerTitle={i18next.t("devices")}
                                    userLogout={this.userLogout}
                                    loadFooterHeight={this.loadJavaScript}
                                    loginRedirect={this.state.loginRedirect}
                                />
                                : <NotAccessOfPage
                                    permissionList={this.state.userPermissionList}
                                    userLogout={(this.userLogout)}/>
                        }/>
                        <Route path="/clients" element={
                            (this.state.listSidebarMenu.includes('manage_clients'))
                                ? <Clients
                                    permissionList={this.state.userPermissionList}
                                    pageTitle={"Backpack Media - " + i18next.t("clients")}
                                    headerTitle={i18next.t("clients")}
                                    userLogout={this.userLogout}
                                    loadFooterHeight={this.loadJavaScript}
                                    loginRedirect={this.state.loginRedirect}
                                />
                                : <NotAccessOfPage
                                    permissionList={this.state.userPermissionList}
                                    userLogout={(this.userLogout)}/>
                        }/>
                        <Route path="/partners" element={
                            (this.state.listSidebarMenu.includes('manage_partners'))
                                ? <Partners
                                    permissionList={this.state.userPermissionList}
                                    pageTitle={"Backpack Media - " + i18next.t("partners")}
                                    headerTitle={i18next.t("partners")}
                                    userLogout={this.userLogout}
                                    loadFooterHeight={this.loadJavaScript}
                                    loginRedirect={this.state.loginRedirect}
                                />
                                : <NotAccessOfPage
                                    permissionList={this.state.userPermissionList}
                                    userLogout={(this.userLogout)}/>
                        }/>
                        <Route path="/promoters" element={
                            (this.state.listSidebarMenu.includes('manage_promoters'))
                                ? <Promoters
                                    permissionList={this.state.userPermissionList}
                                    pageTitle={"Backpack Media - " + i18next.t("promoters")}
                                    headerTitle={i18next.t("promoters")}
                                    userLogout={this.userLogout}
                                    loadFooterHeight={this.loadJavaScript}
                                    loginRedirect={this.state.loginRedirect}
                                />
                                : <NotAccessOfPage
                                    permissionList={this.state.userPermissionList}
                                    userLogout={(this.userLogout)}/>
                        }/>
                        <Route path="/promoter_route" element={
                            (this.state.listSidebarMenu.includes('manage_promoter_routes'))
                                ? <PromoterRoute
                                    permissionList={this.state.userPermissionList}
                                    pageTitle={"Backpack Media - " + i18next.t("promoter_route")}
                                    headerTitle={i18next.t("promoter_route")}
                                    userLogout={this.userLogout}
                                    loadFooterHeight={this.loadJavaScript}
                                    loginRedirect={this.state.loginRedirect}
                                />
                                : <NotAccessOfPage
                                    permissionList={this.state.userPermissionList}
                                    userLogout={(this.userLogout)}/>
                        }/>
                        <Route path="/add_new_promoter_route" element={
                            (this.state.listSidebarMenu.includes('manage_promoter_routes'))
                                ? <AddPromoterRoute
                                    permissionList={this.state.userPermissionList}
                                    pageTitle={"Backpack Media - " + i18next.t("add_new_promoter_route")}
                                    headerTitle={i18next.t("add_new_promoter_route")}
                                    userLogout={this.userLogout}
                                    loadFooterHeight={this.loadJavaScript}
                                    isEdit={false}
                                    clientUuid={this.state.uuid}
                                    loginRedirect={this.state.loginRedirect}
                                />
                                : <NotAccessOfPage
                                    permissionList={this.state.userPermissionList}
                                    userLogout={(this.userLogout)}/>
                        }/>
                        <Route path="/edit_promoter_route/" element={
                            (this.state.listSidebarMenu.includes('manage_promoter_routes'))
                                ? <AddPromoterRoute
                                    permissionList={this.state.userPermissionList}
                                    pageTitle={"Backpack Media -  " + i18next.t("edit_promoter_route")}
                                    headerTitle={i18next.t("edit_promoter_route")}
                                    userLogout={this.userLogout}
                                    loadFooterHeight={this.loadJavaScript}
                                    isEdit={true}
                                    clientUuid={this.state.uuid}
                                    loginRedirect={this.state.loginRedirect}
                                />
                                : <NotAccessOfPage
                                    permissionList={this.state.userPermissionList}
                                    userLogout={(this.userLogout)}/>
                        }/>
                        <Route path="/ad_campaigns" element={
                            (this.state.listSidebarMenu.includes('manage_campaigns'))
                                ? <AdCampaigns
                                    permissionList={this.state.userPermissionList}
                                    pageTitle={"Backpack Media - " + i18next.t("ad_campaigns")}
                                    headerTitle={i18next.t("ad_campaigns")}
                                    userLogout={this.userLogout}
                                    loadFooterHeight={this.loadJavaScript}
                                    loginRedirect={this.state.loginRedirect}
                                />
                                : <NotAccessOfPage
                                    permissionList={this.state.userPermissionList}
                                    userLogout={(this.userLogout)}/>
                        }/>
                        <Route path="/live_tracking" element={
                            (this.state.listSidebarMenu.includes('manage_live_tracking'))
                                ? <LiveTracking
                                    permissionList={this.state.userPermissionList}
                                    pageTitle={"Backpack Media - " + i18next.t("live_tracking")}
                                    headerTitle={i18next.t("live_tracking")}
                                    userLogout={this.userLogout}
                                    loadFooterHeight={this.loadJavaScript}
                                    loginRedirect={this.state.loginRedirect}
                                />
                                : <NotAccessOfPage
                                    permissionList={this.state.userPermissionList}
                                    userLogout={(this.userLogout)}/>
                        }/>
                        <Route path="/promotions" element={
                            (this.state.listSidebarMenu.includes('manage_promotions'))
                                ? <Promotions
                                    permissionList={this.state.userPermissionList}
                                    pageTitle={"Backpack Media - " + i18next.t("promotions")}
                                    headerTitle={i18next.t("promotions")}
                                    userLogout={this.userLogout}
                                    loadFooterHeight={this.loadJavaScript}
                                    loginRedirect={this.state.loginRedirect}
                                />
                                : <NotAccessOfPage
                                    permissionList={this.state.userPermissionList}
                                    userLogout={(this.userLogout)}/>
                        }/>
                        <Route path="/users" element={
                            (this.state.listSidebarMenu.includes('manage_users'))
                                ? <Users
                                    permissionList={this.state.userPermissionList}
                                    pageTitle={"Backpack Media - " + i18next.t("users")}
                                    headerTitle={i18next.t("users")}
                                    userLogout={this.userLogout}
                                    loadFooterHeight={this.loadJavaScript}
                                    loginRedirect={this.state.loginRedirect}
                                />
                                : <NotAccessOfPage
                                    permissionList={this.state.userPermissionList}
                                    userLogout={(this.userLogout)}/>
                        }/>
                        <Route path="/roles" element={
                            (this.state.listSidebarMenu.includes('manage_roles'))
                                ? <Roles
                                    permissionList={this.state.userPermissionList}
                                    pageTitle={"Backpack Media - " + i18next.t("roles")}
                                    headerTitle={i18next.t("roles")}
                                    userLogout={this.userLogout}
                                    loadFooterHeight={this.loadJavaScript}
                                    loginRedirect={this.state.loginRedirect}
                                />
                                : <NotAccessOfPage
                                    permissionList={this.state.userPermissionList}
                                    userLogout={(this.userLogout)}/>
                        }/>
                        <Route path="/apk_upload" element={
                            (this.state.listSidebarMenu.includes('manage_apk'))
                                ? <APKUpload
                                    permissionList={this.state.userPermissionList}
                                    pageTitle={"Backpack Media - " + i18next.t("apk_upload")}
                                    headerTitle={i18next.t("apk_upload")}
                                    userLogout={this.userLogout}
                                    loadFooterHeight={this.loadJavaScript}
                                    loginRedirect={this.state.loginRedirect}
                                />
                                : <NotAccessOfPage
                                    permissionList={this.state.userPermissionList}
                                    userLogout={(this.userLogout)}/>
                        }/>
                        <Route path="/bookings" element={
                            (this.state.listSidebarMenu.includes('manage_bookings'))
                                ? <Bookings
                                    permissionList={this.state.userPermissionList}
                                    pageTitle={"Backpack Media - " + i18next.t("bookings")}
                                    headerTitle={i18next.t("bookings")}
                                    userLogout={this.userLogout}
                                    loadFooterHeight={this.loadJavaScript}
                                    loginRedirect={this.state.loginRedirect}
                                />
                                : <NotAccessOfPage
                                    permissionList={this.state.userPermissionList}
                                    userLogout={(this.userLogout)}/>
                        }/>
                        <Route path="/cities" element={
                            (this.state.listSidebarMenu.includes('manage_cities'))
                                ? <Cities
                                    permissionList={this.state.userPermissionList}
                                    pageTitle={"Backpack Media - " + i18next.t("cities")}
                                    headerTitle={i18next.t("cities")}
                                    userLogout={this.userLogout}
                                    loadFooterHeight={this.loadJavaScript}
                                    loginRedirect={this.state.loginRedirect}
                                />
                                : <NotAccessOfPage
                                    permissionList={this.state.userPermissionList}
                                    userLogout={(this.userLogout)}/>
                        }/>
                        <Route path="*" element={
                            <NotFound pageTitle={"Backpack Media - 404 Not Found"}/>
                        }/>
                    </Routes>
                    <Footer/>
                </BrowserRouter>
            </>
        );
    }
}

export default App;
